<script setup>
import {Link} from '@inertiajs/vue3';

defineProps({
    href: String,
    as: String,
    target: String,
});

const classes = 'block px-5 py-3 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition'
</script>

<template>
    <button v-if="as === 'button'"
            :class="['w-full text-left', classes]"
            type="submit">
        <slot/>
    </button>

    <a v-else-if="as ==='a'" :class="classes"
       :href="href"
       :target="target">
        <slot/>
    </a>

    <Link v-else :class="classes"
          :href="href"
          :target="target">
        <slot/>
    </Link>
</template>
