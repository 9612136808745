<script setup>
import {computed, onMounted, onUnmounted, ref} from 'vue';

const props = defineProps({
    align: {
        type: String,
        default: 'right',
    },
    widthClass: {
        type: String,
        default: 'w-48',
    },
    contentClasses: {
        type: Array,
        default: () => ['py-1', 'bg-white'],
    },
});

let open = ref(false);

const closeOnEscape = (e) => {
    if (open.value && e.key === 'Escape') {
        open.value = false;
    }
};

onMounted(() => document.addEventListener('keydown', closeOnEscape));
onUnmounted(() => document.removeEventListener('keydown', closeOnEscape));

const alignmentClasses = computed(() => {
    return props.align === 'left' ? 'origin-top-left left-0' :
        props.align === 'right' ? 'origin-top-right right-0' :
            props.align === 'center' ? 'origin-top left-1/2 translate-x-[-50%]' :
                '';
});
</script>

<template>
    <div class="relative">
        <div @click="open = ! open">
            <slot name="trigger"/>
        </div>

        <!-- Full Screen Dropdown Overlay -->
        <div v-show="open" class="fixed inset-0 z-40" @click="open = false"/>

        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div
                v-show="open"
                :class="[widthClass, alignmentClasses]"
                class="absolute z-50 mt-3 rounded-xl shadow-xl border border-gray-200"
                style="display: none;"
                @click="open = false"
            >
                <div :class="contentClasses" class="rounded-xl ring-1 ring-black ring-opacity-5">
                    <slot name="content"/>
                </div>
            </div>
        </transition>
    </div>
</template>
